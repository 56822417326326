import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sibling'];

  connect = () => {
    this.inheritHeightFromHighestSibling();
    window.addEventListener('resize', this.resetHeight, true);
  };

  inheritHeightFromHighestSibling = () => {
    if (!this.hasSiblingTarget) return;

    const maxHeight = Math.max(...this.siblingTargets.map(el => el.clientHeight)) + 'px';
    this.siblingTargets.forEach(el => {
      el.style.height = maxHeight;
    });
  };

  resetHeight = () => {
    if (!this.hasSiblingTarget) return;

    this.siblingTargets.forEach(el => {
      el.style.height = 'auto';
    });
    this.inheritHeightFromHighestSibling();
  };
}
