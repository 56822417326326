require('bootstrap-sass/assets/javascripts/bootstrap/tooltip');
require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
require('bootstrap-sass/assets/javascripts/bootstrap/modal');
require('bootstrap-sass/assets/javascripts/bootstrap/alert');

require('./utils/appsignal_window_events');

require('@rails/ujs').start();
require('@rails/activestorage').start();

import './utils/jquery';

require('../../vendor/assets/javascripts/bootstrap-datepicker/bootstrap-datepicker'); // not using npm because of the following issue: https://github.com/uxsolutions/bootstrap-datepicker/pull/2234

require('turbolinks-csp').start();
require('./utils/turbolinks_adapter');

import './controllers';

require('./utils/on_page_show');

$(document).on('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip();
});
