/* https://github.com/hotwired/stimulus/issues/328 */

window.addEventListener('pageshow', event => {
  window.Stimulus.controllers.forEach(controller => {
    if (typeof controller.pageShow === 'function') {
      controller.pageShow(event.persisted);
    }
  });
});

window.addEventListener('pagehide', () => {
  window.Stimulus.controllers.forEach(controller => {
    if (typeof controller.pageHide === 'function') {
      controller.pageHide();
    }
  });
});
