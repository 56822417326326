import { Controller } from '@hotwired/stimulus';

export class SmartQuestionnaireFormControllerShared extends Controller {
  static targets = ['surveyContainer'];

  connect() {
    let widgetUrl = this.data.get('widget-url');
    let submitPath = this.data.get('submit-path');
    let url = this.data.get('url');
    let target = this.surveyContainerTarget;
    let redirectUrl = this.data.get('redirect-url');
    let onPageRendered = this.onPageRendered;

    this.resetSqFormParams();
    this.loadScript(widgetUrl).then(function () {
      let questionnaire = new window.mh.MedihiveSmartQuestionnaire(url, target, redirectUrl);
      questionnaire.render();
      document.addEventListener('mh-sq:load', onPageRendered);
      document.removeEventListener('mh-sq:finish', questionnaire.onQuestionnaireFinished);
    });
    this.saveAnswers(submitPath);
  }

  loadScript(src) {
    return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement('script');
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    document.addEventListener('mh-sq:expired-session', this.onExpiredSession);
  }

  removeListeners() {
    document.removeEventListener('mh-sq:expired-session', this.onExpiredSession);
  }

  saveAnswers(submitPath) {
    document.addEventListener('mh-sq:finish', function () {
      $.ajax({
        type: 'POST',
        url: submitPath
      });
    });
  }

  onPageRendered = () => {
    $('.sq-confirm-answer-btn').hide();
  };

  onExpiredSession = () => {
    window.location.reload();
  };
}
