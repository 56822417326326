export class Helpers {
  static debounce(callback, wait = 300) {
    let timeoutId = null;

    return (...args) => {
      window.clearTimeout(timeoutId);

      timeoutId = window.setTimeout(() => {
        callback(null, args);
      }, wait);
    };
  }

  static debouncePromise(fn, wait = 300) {
    let timeoutId = undefined;

    return function debounced(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      return new Promise(resolve => {
        timeoutId = setTimeout(() => resolve(fn(...args)), wait);
      });
    };
  }

  static clickOutsideElement(closestSelectors, callback) {
    document.addEventListener('click', event => {
      if (!event.target.closest(closestSelectors.join(', '))) {
        callback();
      }
    });
  }

  static isEmpty(str) {
    return str || /^\s*$/.test(str);
  }
}
