import { Controller } from '@hotwired/stimulus';

export class StripeCardElementControllerShared extends Controller {
  static targets = ['form', 'button', 'helpBlock'];

  connect() {
    import('@stripe/stripe-js').then(stripe => {
      this.createStripeElements(stripe);
    });
  }

  async createStripeElements(stripe) {
    const stripePublishableKey = this.formTarget.getAttribute('data-publishable-key');
    const locale = this.formTarget.dataset.locale || 'en';
    const stripe_connected = this.formTarget.getAttribute('data-stripe-connected') === 'true';
    if (stripe_connected) {
      const stripe_account = this.formTarget.getAttribute('data-stripe-account');
      this.stripe = await stripe.loadStripe(stripePublishableKey, { stripeAccount: stripe_account });
    } else {
      this.stripe = await stripe.loadStripe(stripePublishableKey);
    }
    const elements = this.stripe.elements({ locale: locale });

    const defaultStyle = {
      base: {
        color: '#555',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#a94442',
        iconColor: '#a94442'
      }
    };

    this.card = elements.create('card', { style: defaultStyle, hidePostalCode: true });
    this.card.mount('#card-element');
    this.card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      displayError.querySelector('span').textContent = event.error != null ? event.error.message : '';
    });

    this.buttonTarget.disabled = false;
  }
}
