import { Controller } from '@hotwired/stimulus';
require('detectrtc/DetectRTC');
import { appsignal } from '../utils/appsignal';
import { VideoLayout } from '../utils/video_layout';
import { Helpers } from '../utils/helpers';

export default class extends Controller {
  static targets = [
    'chatBody',
    'chatCollapseIcon',
    'chatContainer',
    'connectButton',
    'connectButtonIcon',
    'connectButtonLabel',
    'cycleVideoButton',
    'disconnectButton',
    'spinner',
    'readyText',
    'otherPartyText',
    'permissionPanel',
    'deniedPanel',
    'notSupportedPanel',
    'notSupportedPanelInfo',
    'startSharingDesktopButton',
    'stopSharingDesktopButton',
    'videoViewPanel',
    'muteButton',
    'unmuteButton',
    'userHeader'
  ];

  connect() {
    this.videoLayout = new VideoLayout();

    this.apiKey = this.data.get('apiKey');
    this.sessionId = this.data.get('sessionId');
    this.tokenId = this.data.get('token');
    this.participantName = this.data.get('participantName');
    this.participantRole = this.data.get('participantRole');
    this.rootPath = this.data.get('rootPath');

    this.session = null;
    this.sessionConnected = false;

    this.publisher = null;
    this.publishing = false;

    this.subscriber = null;

    this.remoteStream = null;
    this.localStream = null;

    this.supportsMultipleCameras = false;

    this.videoLayout.doLayout(this.publishing);
    this.checkMultipleCamerasSupport();

    this.desktopSubscriber = null;
    this.supportsDesktopSharing = false;
    this.desktopSharingEnabled = this.data.get('desktopSharingEnabled') == 'true';
    this.checkDesktopSharingSupport();
    this.desktopPublisher = null;
    this.desktopPublishing = false;
    this.startSharingDesktopButtonTitle = '';
    this.localDesktopStream = null;
    this.remoteDesktopStream = null;

    this.screenshottingAvailable = this.data.get('screenshottingAvailable') == 'true';
    this.style = { nameDisplayMode: 'off', buttonDisplayMode: 'off' };

    this.chatAvailable = this.data.get('chatAvailable') == 'true';

    $(window).bind('resize', this.onWindowResize);

    this.init();
  }

  init() {
    DetectRTC.load(() => {
      if (DetectRTC.isWebRTCSupported === false) {
        this.showUnsupportedPanel("Your browser doesn't support video. Please change your browser.");
      } else if (DetectRTC.hasMicrophone === false) {
        this.showUnsupportedPanel('We could not detect microphone. Please add one or change device.');
      } else if (DetectRTC.isWebsiteHasWebcamPermissions && DetectRTC.isWebsiteHasMicrophonePermissions) {
        this.connectToSession();
      } else {
        this.showPanel(this.permissionPanelTarget);
      }
    });
  }

  // Public

  start() {
    this.disableUI();

    console.log('[VC] Connecting consultation.');
    this.readyTextTarget.classList.add('hidden');
    this.startPublishing();
  }

  stop() {
    this.disableUI();

    console.log('[VC] Disconnecting consultation.');
    this.stopPublishing();
    this.readyTextTarget.classList.remove('hidden');
  }

  mute() {
    this.toggleSelfMute(false);
  }

  unmute() {
    this.toggleSelfMute(true);
  }

  toggleSelfMute(mute) {
    this.publisher.publishAudio(mute);
  }

  cycleVideo() {
    if (this.publisher) {
      this.publisher.cycleVideo();
    }
  }

  async requestHardwareAccess() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      this.showPanel(this.spinnerTarget);
      this.connectToSession();
    } catch (err) {
      this.showPanel(this.deniedPanelTarget);
    }
  }

  // Session

  connectToSession() {
    console.log('[VC] Creating session.');

    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.on('streamCreated', this.onRemoteStreamCreated);
    this.session.on('streamDestroyed', this.onRemoteStreamDestroyed);
    this.session.on('streamPropertyChanged', this.onStreamPropertyChanged);

    console.log('[VC] Connecting session.');
    this.session.connect(this.tokenId, this.onSessionConnected);
  }

  onSessionConnected = error => {
    console.log('[VC] Session connected.');

    if (error) {
      this.displayError(error);
      this.enableUI();
    } else {
      this.sessionConnected = true;
      this.readyTextTarget.classList.remove('hidden');
      this.startPublishing();
    }
    this.updateControlsUI();
  };

  onStreamPropertyChanged = () => {
    this.updateControlsUI();
  };

  // Publisher

  startPublishing() {
    console.log('[VC] Creating publisher.');
    this.publisher = OT.initPublisher(
      'video-user-container',
      { name: this.participantName, insertMode: 'append', width: '100%', height: '100%', style: this.style },
      this.onPublisherCreated
    );
    this.publisher.on('streamCreated', this.onLocalStreamCreated);
    this.publisher.on('streamDestroyed', this.onLocalStreamDestroyed);
    this.publisher.on('accessAllowed', this.onAccessAllowed);
    this.publisher.on('accessDenied', this.onAccessDenied);
  }

  stopPublishing() {
    this.session.unpublish(this.publisher);
    if (this.desktopPublisher) this.session.unpublish(this.desktopPublisher);
    if (this.desktopSubscriber) this.session.unsubscribe(this.desktopSubscriber);
    if (this.subscriber) this.session.unsubscribe(this.subscriber);
    this.subscriber = null;
    this.desktopSubscriber = null;
    this.publisher = null;
    this.desktopPublisher = null;
    this.publishing = false;
    this.desktopPublishing = false;
  }

  onPublisherCreated = error => {
    if (error) {
      this.displayError(error);
      this.enableUI();
    } else {
      console.log('[VC] Publisher has been created.');
      this.session.publish(this.publisher, this.onPublishing);
    }
  };

  onPublishing = error => {
    if (error) {
      this.displayError(error);
    } else {
      console.log('[VC] Publishing has started');
      this.publishing = true;

      this.videoLayout.doPublishingLayout();
    }

    this.enableUI();
    this.showPanel(this.videoViewPanelTarget);
    this.subscribe();
  };

  // Streams

  subscribe(stream = this.remoteStream) {
    if (stream == null) return null;
    if (this.publishing == false) return null;
    console.log('[VC] Subscribing to stream.');

    if (stream.videoType == 'screen' && this.desktopSubscriber == null) {
      this.desktopSubscriber = this.session.subscribe(
        stream,
        'video-view',
        { insertMode: 'append', width: '100%', height: '100%', style: this.style },
        this.onDesktopSubscription()
      );
    } else if (this.subscriber == null) {
      this.subscriber = this.session.subscribe(
        stream,
        'video-view',
        { insertMode: 'append', width: '100%', height: '100%', style: this.style },
        this.onStreamSubscription()
      );
    }
    this.updateControlsUI();
  }

  unsubscribe(stream) {
    console.log(`[VC] Unsubscribing from ${stream.videoType} stream.`);

    this.remoteStream = null;
    if (this.subscriber) {
      this.session.unsubscribe(this.subscriber);
      this.subscriber = null;
    }
    this.updateControlsUI();
  }

  onRemoteStreamCreated = event => {
    console.log(`[VC] Incoming ${event.stream.videoType} stream, 
      local.connection.id = ${this.session.connection.id}, 
      incoming.connection.id = ${event.stream.connection.id}
    `);

    if (event.stream.videoType == 'screen') {
      this.remoteDesktopStream = event.stream;
    } else {
      this.remoteStream = event.stream;
    }

    this.toggleOtherPartyText(false);
    this.subscribe(event.stream);
    this.updateControlsUI();
  };

  onRemoteStreamDestroyed = event => {
    console.log('[VC] Remote stream destroyed');

    if (event.stream.videoType == 'screen') {
      this.unsubscribeFromDesktop(event.stream);
    } else {
      this.unsubscribe(event.stream);
    }

    this.stopSharingDesktop();
    this.toggleOtherPartyText(true);
    this.updateControlsUI();
  };

  onLocalStreamCreated = event => {
    console.log('[VC] Local ' + event.stream.videoType + ' stream created.');

    this.localStream = event.stream;
    this.updateControlsUI();
  };

  onLocalStreamDestroyed = event => {
    console.log('[VC] Local ' + event.stream.videoType + ' stream destroyed.');

    this.stopSharingDesktop();
    this.localStream = null;
    this.publisher = null;
    this.publishing = false;
    this.videoLayout.doLayout(this.publishing);
    this.enableUI();
    this.updateControlsUI();
  };

  onStreamSubscription = error => {
    if (error) {
      this.displayError(error);
    }
    this.updateControlsUI();
  };

  // UI Manipulation

  disableUI() {
    this.connectButtonTarget.disabled = true;
    this.disconnectButtonTarget.disabled = true;
    this.cycleVideoButtonTarget.disabled = true;
    this.updateControlsUI();
  }

  enableUI() {
    this.connectButtonTarget.disabled = false;
    this.disconnectButtonTarget.disabled = false;
    this.cycleVideoButtonTarget.disabled = false;
    this.updateControlsUI();
  }

  displayError = error => {
    const ignoredErrors = ['OT_TIMEOUT', 'OT_NOT_CONNECTED', 'OT_CONNECT_FAILED'];

    if (error.name == 'OT_AUTHENTICATION_ERROR') {
      alert('This consultation has ended. You can no longer connect to this consultation.');
      TurbolinksAdapter.visit(this.rootPath, { action: 'resetRoot' });
    } else if (error.name == 'OT_USER_MEDIA_ACCESS_DENIED' && !this.publishing) {
      alert('You need to allow access to your camera and microphone. Please reload browser window.');
    } else if (ignoredErrors.includes(error.name)) {
      console.log(`[VC] Error, name = ${error.name}`, error);
    } else {
      console.log(`[VC] Error, name = ${error.name}`, error);
      appsignal.sendError(error);
    }
  };

  updateConnectedUI() {
    let connected = this.localStream != null;

    this.connectButtonTarget.classList.toggle('hidden', connected);
    this.disconnectButtonTarget.classList.toggle('hidden', !connected);
    this.readyTextTarget.classList.toggle('hidden', connected);
    this.cycleVideoButtonTarget.classList.toggle('hidden', !connected || !this.supportsMultipleCameras);
    this.userHeaderTarget.classList.toggle('hidden', !connected);
  }

  updateControlsUI() {
    this.updateConnectedUI();
    this.updateChargingUI();
    this.updateScreenshotUI();
    this.updateSharingUI();
    this.updateMutingUI();
    this.updateChatUI();
  }

  updateChargingUI() {
    if (this.participantRole != 'clinician') return null;

    let showChargeButton = this.publishing && this.subscriber != null;
    $('#chargeButton').toggleClass('hidden', !showChargeButton);
  }

  updateChatUI() {
    if (!this.chatAvailable) return null;

    let showChat = this.areBothConnected() || (this.participantRole == 'clinician' && this.publishing);
    this.chatContainerTarget.classList.toggle('hidden', !showChat);
  }

  areBothConnected() {
    return this.remoteStream != null && this.publishing;
  }

  updateScreenshotUI() {
    if (!this.screenshottingAvailable) return null;

    let both_connected = this.localStream != null && this.remoteStream != null;
    $('#screenshotButton').prop('disabled', !both_connected);
    $('#screenshotButton').toggleClass('hidden', !both_connected);
  }

  updateSharingUI = () => {
    if (!this.desktopSharingEnabled) return null;

    this.startSharingDesktopButtonTarget.title = this.startSharingDesktopButtonTitle;
    this.startSharingDesktopButtonTarget.disabled =
      !this.publishing || this.desktopPublishing || !this.supportsDesktopSharing;
    this.startSharingDesktopButtonTarget.classList.toggle(
      'hidden',
      !this.publishing || this.desktopPublishing || this.subscriber == null
    );

    this.stopSharingDesktopButtonTarget.disabled = !this.publishing || !this.desktopPublishing;
    this.stopSharingDesktopButtonTarget.classList.toggle('hidden', !this.desktopPublishing);
  };

  updateMutingUI = () => {
    let hasAudio = this.localStream == null ? false : this.localStream.hasAudio;
    this.muteButtonTarget.disabled = !hasAudio || !this.publishing;
    this.muteButtonTarget.classList.toggle('hidden', !hasAudio || !this.publishing);

    this.unmuteButtonTarget.disabled = hasAudio || !this.publishing;
    this.unmuteButtonTarget.classList.toggle('hidden', hasAudio || !this.publishing);
  };

  toggleOtherPartyText(boolean) {
    this.otherPartyTextTarget.classList.toggle('hidden', boolean);
    if (this.participantRole == 'clinician') {
      this.otherPartyTextTarget.textContent = 'Your patient is waiting ...';
    } else {
      this.otherPartyTextTarget.textContent = 'Doctor is ready to see you ...';
    }
    this.updateScreenshotUI();
  }

  onChatCollapse() {
    if (!this.chatAvailable) return null;

    let openChatClass = 'fa-chevron-up';
    let closeChatClass = 'fa-chevron-down';
    let chatOpening =
      this.chatBodyTarget.classList.contains('collapse') && this.chatBodyTarget.classList.contains('in');
    this.chatCollapseIconTarget.classList.toggle(openChatClass, chatOpening);
    this.chatCollapseIconTarget.classList.toggle(closeChatClass, !chatOpening);
  }

  onWindowResize = Helpers.debounce(() => {
    this.videoLayout.doLayout(this.publishing);
  }, 100);

  // Panels switching

  showUnsupportedPanel(reason) {
    this.notSupportedPanelInfoTarget.textContent = reason;
    this.notSupportedPanelTarget.classList.remove('hidden');
    this.showPanel(this.notSupportedPanelTarget);
  }

  showPanel(panel) {
    this.videoViewPanelTarget.classList.add('hidden');
    this.deniedPanelTarget.classList.add('hidden');
    this.permissionPanelTarget.classList.add('hidden');
    this.notSupportedPanelTarget.classList.add('hidden');
    this.spinnerTarget.classList.add('hidden');
    panel.classList.remove('hidden');
  }

  // Access

  checkMultipleCamerasSupport() {
    OT.getDevices((error, devices) => {
      let numberOfCameras = 0;

      devices.forEach(value => {
        if (value.kind === 'videoInput') {
          numberOfCameras += 1;
        }
      });

      this.supportsMultipleCameras = numberOfCameras > 1;
    });
  }

  onAccessAllowed() {}

  onAccessDenied() {}

  checkAccess() {
    if (!DetectRTC.isWebsiteHasMicrophonePermissions) alert('Please grant microphone access.');
    if (!DetectRTC.isWebsiteHasWebcamPermissions) alert('Please grant webcam access.');
  }

  // Desktop sharing

  checkDesktopSharingSupport = () => {
    OT.checkScreenSharingCapability(response => {
      if (!response.supported || response.extensionRegistered === false) {
        this.supportsDesktopSharing = false;
        this.startSharingDesktopButtonTitle =
          'Your browser does not support screen sharing. Please try Chrome, Firefox or Opera browser';
      } else if (response.extensionInstalled === false) {
        this.supportsDesktopSharing = false;
        this.startSharingDesktopButtonTitle = 'Your browser requires installing extension to support screen sharing';
        // Prompt to install the extension.
      } else {
        this.supportsDesktopSharing = true;
      }
      this.updateControlsUI();
    });
  };

  startSharingDesktop() {
    this.desktopPublisher = OT.initPublisher(
      'screen-preview',
      { videoSource: 'screen' },
      this.onDesktopPublisherCreated
    );
    this.desktopPublisher.on('streamCreated', this.onLocalDesktopStreamCreated);
    this.desktopPublisher.on('streamDestroyed', this.onLocalDesktopStreamDestroyed);
  }

  stopSharingDesktop() {
    if (this.desktopPublishing == false) return null;

    this.session.unpublish(this.desktopPublisher);
    this.desktopPublisher = null;
    this.desktopPublishing = false;
  }

  onDesktopPublisherCreated = error => {
    if (error) {
      this.displayError(error);
    } else {
      console.log('[VC] Desktop publisher has been created.');
      this.session.publish(this.desktopPublisher, this.onDesktopPublishing);
    }
  };

  onDesktopPublishing = error => {
    if (error) {
      this.displayError(error);
    } else {
      console.log('[VC] Desktop publishing has started');
      this.desktopPublishing = true;
      this.updateControlsUI();
    }
  };

  onLocalDesktopStreamCreated = event => {
    console.log('[VC] Local ' + event.stream.videoType + ' stream created.');

    this.localDesktopStream = event.stream;
    this.updateControlsUI();
  };

  onLocalDesktopStreamDestroyed = event => {
    console.log('[VC] Local ' + event.stream.videoType + ' stream destroyed.');

    this.localDesktopStream = null;
    this.desktopPublisher = null;
    this.desktopPublishing = false;
    this.updateControlsUI();
  };

  onDesktopSubscription = error => {
    if (error) {
      this.displayError(error);
    } else {
      this.desktopSubscribing = true;
      this.toggleIncomingCamVideo(false);
    }
    this.updateControlsUI();
  };

  unsubscribeFromDesktop(stream) {
    console.log(`[VC] Unsubscribing from ${stream.videoType} stream.`);

    this.remoteDesktopStream = null;
    if (this.desktopSubscriber) {
      this.session.unsubscribe(this.desktopSubscriber);
      this.desktopSubscriber = null;
      this.toggleIncomingCamVideo(true);
    }
  }

  toggleIncomingCamVideo(boolean) {
    if (this.subscriber == null) return null;

    this.subscriber.subscribeToVideo(boolean);
    this.subscriber.element.classList.toggle('hidden', !boolean);
  }
}
