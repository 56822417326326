import { Controller } from '@hotwired/stimulus';
import { Helpers } from '../../utils/helpers';

export class QuestionnaireFormControllerShared extends Controller {
  static targets = ['form'];

  onAnswerChange = Helpers.debounce(() => {
    this.evaluateForm();
  }, 100);

  evaluateForm = () => {
    if (!this.shouldEvaluate()) return null;

    $.ajax({
      headers: {
        Accept: 'text/javascript'
      },
      converters: {
        'text script': function (text) {
          jQuery.globalEval(text, { nonce: document.querySelector('meta[name=csp-nonce]')?.content });
          return text;
        }
      },
      type: 'POST',
      url: this.data.get('evaluation-url'),
      data: $('form').serialize()
    });
  };

  shouldEvaluate = () => {
    return this.data.get('should-evaluate') === 'true';
  };
}
