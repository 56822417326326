import { Controller } from '@hotwired/stimulus';
import { WidgetInstance } from 'friendly-challenge';

export default class extends Controller {
  connect() {
    const element = document.querySelector('#captcha-widget');
    const nonce = document.querySelector('meta[name=csp-nonce]')?.content;
    const options = { sitekey: this.element.dataset.sitekey, styleNonce: nonce };
    const widget = new WidgetInstance(element, options);
    widget.start();
  }
}
