import { Controller } from '@hotwired/stimulus';
import IMask from 'imask';
import { DateTime } from 'luxon';

export default class DatepickerControllerShared extends Controller {
  connect() {
    this.initializeDatepicker();
  }

  initializeDatepicker() {
    $(this.element)
      .datepicker(this.datepickerOptions)
      .on('changeDate', e => {
        let selectedDate = DateTime.fromFormat(e.target.value, 'dd/MM/yyyy');
        this.element.dispatchEvent(new CustomEvent('change', { detail: selectedDate }));
      });
    if (this.element.tagName === 'DIV') return;
    IMask(this.element, {
      mask: 'd/m/Y',
      lazy: true,
      overwrite: true,
      blocks: {
        d: { mask: IMask.MaskedRange, from: 1, to: 31, maxLength: 2 },
        m: { mask: IMask.MaskedRange, from: 1, to: 12, maxLength: 2 },
        Y: { mask: IMask.MaskedRange, from: 1800, to: 2999, maxLength: 4 }
      }
    });
  }

  get datepickerOptions() {
    return {
      autoclose: true,
      format: 'dd/mm/yyyy',
      zIndexOffset: 1076,
      todayHighlight: true,
      weekStart: 1,
      startDate: this.element.dataset.startDate ? new Date(this.element.dataset.startDate) : null,
      endDate: this.element.dataset.endDate ? new Date(this.element.dataset.endDate) : null
    };
  }
}
