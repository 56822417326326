import { ApplicationController, useDebounce } from 'stimulus-use';

export default class extends ApplicationController {
  static targets = ['input', 'element', 'noResult'];
  static debounces = ['onSearching'];

  connect() {
    if (!this.hasInputTarget) return;

    useDebounce(this, { wait: 300 });
  }

  onSearching(event) {
    if (!event.target) return;

    const searchValue = event.target.value.toLowerCase();

    if (!this.elementTargets.length) return;

    this.elementTargets.forEach(element => {
      const elementValue = element.dataset.elementValue && element.dataset.elementValue.toLowerCase();
      var elementAddress = element.dataset.elementAddress && element.dataset.elementAddress.toLowerCase();

      if (!elementAddress) {
        elementAddress = '';
      }

      if (!elementValue) return;

      elementValue.includes(searchValue) || elementAddress.includes(searchValue)
        ? element.classList.remove('hidden')
        : element.classList.add('hidden');
      this.handleResult();
    });
  }

  handleResult() {
    if (!this.noResultTarget) return;

    const visibleResults = this.elementTargets.filter(element => !element.classList.contains('hidden'));

    visibleResults.length === 0
      ? this.noResultTarget.classList.remove('hidden')
      : this.noResultTarget.classList.add('hidden');
  }
}
