import { StripeCardElementControllerShared } from './shared/stripe_card_element_controller.shared';

export default class extends StripeCardElementControllerShared {
  pay = event => {
    event.preventDefault();

    var clientSecret = this.formTarget.getAttribute('data-client-secret');
    var helpBlock = this.helpBlockTarget;
    var formTarget = this.formTarget;

    this.stripe.handleCardPayment(clientSecret, this.card).then(result => {
      if (result.error) {
        helpBlock.innerHTML = result.error.message;
      } else {
        $(formTarget).submit();
      }
    });
  };
}
