import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('push-state', this.pushState);
    $('body').on('click', '.btn-next-patient-booking', this.pushState);
  }

  pushState() {
    history.pushState({}, '');
  }
}
