import { application } from './application';

import BackButtonController from './back_button_controller';
application.register('back-button', BackButtonController);

import BookingWidgetController from './booking_widget_controller';
application.register('booking-widget', BookingWidgetController);

import BusyStateController from './busy_state_controller';
application.register('busy-state', BusyStateController);

import CaptchaController from './captcha_controller';
application.register('captcha', CaptchaController);

import DatepickerController from './datepicker_controller';
application.register('datepicker', DatepickerController);

import DateSelectionController from './date_selection_controller';
application.register('date-selection', DateSelectionController);

import DisableSubmitOverlayController from './disable_submit_overlay_controller';
application.register('disable-submit-overlay', DisableSubmitOverlayController);

import FakeFileInputController from './fake_file_input_controller';
application.register('fake-file-input', FakeFileInputController);

import GaController from './ga_controller';
application.register('ga', GaController);

import InVideoPaymentController from './in_video_payment_controller';
application.register('in-video-payment', InVideoPaymentController);

import InboxController from './inbox_controller';
application.register('inbox', InboxController);

import InheritMaxSiblingHeightController from './inherit_max_sibling_height_controller';
application.register('inherit-max-sibling-height', InheritMaxSiblingHeightController);

import OnlineBookingController from './online_booking_controller';
application.register('online-booking', OnlineBookingController);

import PaymentBeforeConsultationController from './payment_before_consultation_controller';
application.register('payment-before-consultation', PaymentBeforeConsultationController);

import PhoneController from './phone_controller';
application.register('phone', PhoneController);

import PluginsControllerShared from './shared/plugins_controller.shared';
const pluginsController = new PluginsControllerShared();
pluginsController.init();

import PushStateController from './push_state_controller';
application.register('push-state', PushStateController);

import QuestionnaireFormController from './questionnaire_form_controller';
application.register('questionnaire-form', QuestionnaireFormController);

import SessionTimerController from './session_timer_controller';
application.register('session-timer', SessionTimerController);

import SidebarController from './sidebar_controller';
application.register('sidebar', SidebarController);

import SmartQuestionnaireFormController from './smart_questionnaire_form_controller';
application.register('smart-questionnaire-form', SmartQuestionnaireFormController);

import SurveyjsQuestionnaireFormController from './surveyjs_questionnaire_form_controller';
application.register('surveyjs-questionnaire-form', SurveyjsQuestionnaireFormController);

import ThreadController from './thread_controller';
application.register('thread', ThreadController);

import TypeaheadController from './typeahead_controller';
application.register('typeahead', TypeaheadController);

import UnavailableAppointmentController from './unavailable_appointment_controller';
application.register('unavailable-appointment', UnavailableAppointmentController);

import UserVideoPreviewController from './user_video_preview_controller';
application.register('user-video-preview', UserVideoPreviewController);

import VideoRoomController from './video_room_controller';
application.register('video-room', VideoRoomController);

import VideoScreenshotController from './video_screenshot_controller';
application.register('video-screenshot', VideoScreenshotController);

import WaitingRoomController from './waiting_room_controller';
application.register('waiting-room', WaitingRoomController);

import WidgetsSearchController from './widgets_search_controller';
application.register('widgets-search', WidgetsSearchController);
