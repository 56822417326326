import { Controller } from '@hotwired/stimulus';

export class ThreadController extends Controller {
  static targets = ['messageSubject'];

  toggleSubject(element) {
    const messageSubjectElement = element.querySelector('.thread-message-subject');

    messageSubjectElement.classList.toggle('hidden');
  }

  onMessageClick(event) {
    const element = event.currentTarget;
    const wrapper = element.closest('.thread-message-wrapper');

    event.preventDefault();

    if (wrapper.classList.contains('thread-message-last')) return;

    wrapper.classList.toggle('open');
    this.toggleSubject(element);
  }
}
