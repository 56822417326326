import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.resetRadioStateOnUnload();
    this.radioSubmitOnChange();
  }

  resetRadioStateOnUnload() {
    window.addEventListener('unload', this.resetForm(), false);
  }

  resetForm() {
    let selector = document.querySelector('.radio-group');
    if (selector) selector.reset();
  }

  radioSubmitOnChange() {
    $(':radio').on('change', function () {
      $('form').submit();
    });
  }
}
