import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  goBack() {
    this.buttonTarget.disable = true;
    history.back();
    setTimeout(() => window.location.reload(), 100);
  }
}
