export class VideoLayout {
  doLayout = (publishing) => {
    if (publishing) return this.doPublishingLayout();

    const marginHorizontal = $(window).width() <= 970 ? 0 : 15;
    const marginVertical = 15;
    const viewportHeight = $(window).height();

    const maxVideoRoomHeight = viewportHeight - $('#main-nav').height() - 2 * marginVertical;
    const maxVideoRoomWidth = $('#main-content').width() - 2 * marginHorizontal;

    let videoRoomHeight = maxVideoRoomHeight;
    let videoRoomWidth = 1.33 * videoRoomHeight;

    if (videoRoomWidth > maxVideoRoomWidth) {
      videoRoomWidth = maxVideoRoomWidth;
      videoRoomHeight = 0.75 * videoRoomWidth;
    }

    $('#main-nav').show();
    $('#main-footer').show();

    $('#video-app').css('margin', '15px auto');
    $('#video-app').height(videoRoomHeight);
    $('#video-app').width(videoRoomWidth);

    this.doPublisherViewLayout();
  };

  doPublishingLayout() {
    const viewportHeight = $(window).height();
    const viewportWidth = $(window).width();

    $('#main-nav').hide();
    $('#main-footer').hide();

    $('#video-app').css('margin', '0px');
    $('#video-app').height(viewportHeight);
    $('#video-app').width(viewportWidth);

    this.doPublisherViewLayout();
  }

  doPublisherViewLayout() {
    const videoRoomHeight = $('#video-app').height();
    const publisherHeight = videoRoomHeight * 0.25;
    const publisherWidth = (4 / 3) * publisherHeight;

    $('#video-user-container').height(publisherHeight);
    $('#video-user-container').width(publisherWidth);
  }
}
