import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  takeScreenshot() {
    var canvas = document.createElement('canvas');
    var videos = document.querySelectorAll('video');
    var video = videos[videos.length - 1];

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0);

    let dataUrl = canvas.toDataURL('image/png');

    this.downloadScreenshot(dataUrl);
  }

  downloadScreenshot(dataUrl) {
    var hrefElement = document.createElement('a');
    hrefElement.href = dataUrl;

    document.body.append(hrefElement);

    hrefElement.download = 'customerScreenshot.png';
    hrefElement.click();
    hrefElement.remove();
  }
}
