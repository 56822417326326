import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

export class PhoneControllerShared extends Controller {
  static targets = ['phoneInput'];

  connect() {
    const phoneField = intlTelInput(this.phoneInputTarget, {
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js',
      hiddenInput: 'phone_number',
      separateDialCode: true,
      initialCountry: this.phoneInputTarget.getAttribute('data-country'),
      preferredCountries: ['IE', 'CH', 'GB']
    });

    // Format on focusout
    this.phoneInputTarget.addEventListener('focusout', e => {
      e.target.classList.add('busy-loading');
      phoneField.setNumber(phoneField.getNumber());
      e.target.classList.remove('busy-loading');
    });
  }
}
