import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(document).on('click', 'a[data-in-progress]', this.onBeforeAjax);
    $(document).on('ajax:before', 'form', this.onBeforeAjax);
    $(document).on('ajax:before-remote-upload', 'form', this.onBeforeAjax);
  }

  onBeforeAjax = event => {
    const element = event.target;
    let elementsToDisable;

    if (element.tagName == 'FORM') {
      elementsToDisable = event.target.querySelectorAll('input[type=submit], button[type=submit]');

      for (var i = 0; i < elementsToDisable.length; i++) {
        this.disableElement(elementsToDisable[i]);
      }
    } else if (element.tagName == 'A') {
      elementsToDisable = event.target;

      this.disableElement(elementsToDisable);
    }
  };

  disableElement(element) {
    element.disabled = true;

    element.setAttribute('data-disabled-on-submit', true);
    element.classList.add('disabled');

    var disableWithValue = element.getAttribute('data-in-progress');
    if (disableWithValue && disableWithValue.length) {
      element.setAttribute('data-enable-with', this.getValue(element));

      this.setValue(element, disableWithValue);
    }
  }

  enableElement() {
    const elementsToEnable = document.querySelectorAll(
      'input[type=submit][data-disabled-on-submit=true], button[type=submit][data-disabled-on-submit=true], .btn[data-disabled-on-submit=true], a[data-disabled-on-submit=true]'
    );

    for (var i = 0; i < elementsToEnable.length; i++) {
      const element = elementsToEnable[i];

      element.disabled = false;
      element.removeAttribute('data-disabled-on-submit');
      element.classList.remove('disabled');

      var enableWithValue = element.getAttribute('data-enable-with');
      if (enableWithValue && enableWithValue.length) {
        this.setValue(element, enableWithValue);
        element.removeAttribute('data-enable-with');
      }
    }
  }

  getValue(element) {
    if (element.tagName == 'BUTTON' || element.tagName == 'A') {
      return element.innerHTML;
    } else if (element.tagName == 'INPUT') {
      return element.value;
    }
  }

  setValue = function (element, value) {
    if (element.tagName == 'BUTTON' || element.tagName == 'A') {
      element.innerHTML = value;
    } else if (element.tagName == 'INPUT') {
      element.value = value;
    }
  };
}
