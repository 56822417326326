import { Controller } from '@hotwired/stimulus';
import { Helpers } from '../../utils/helpers';

export class SideBarControllerShared extends Controller {
  static targets = ['sidebar'];

  connect() {
    this.onOpen();
    this.onResize();
    this.createSidebarObserver();
    this.setPosition();
    this.bindToOpen();
    this.bindToClose();
    this.bindClickOutsideElement();
    this.bindToOpenUrl();
    this.bindToScrollTop();
  }

  onOpen() {
    if (!this.hasSidebarTarget) return;

    const buttons = document.querySelectorAll('[data-sidebar-open]');
    const debounced = Helpers.debounce(() => {
      this.onToggle(true);
      this.sidebarTarget.dispatchEvent(new CustomEvent('sidebar:opened', { bubbles: true }));
    }, 100);

    if (!buttons.length) return false;

    buttons.forEach(button => {
      button.addEventListener('click', () => debounced());
    });

    this.bindClickOutsideElement();
  }

  onClose() {
    const buttons = document.querySelectorAll('[data-sidebar-close]');
    const debounced = Helpers.debounce(() => {
      this.onToggle(false);
      this.sidebarTarget.dispatchEvent(new CustomEvent('sidebar:closed', { bubbles: true }));
    }, 100);

    if (!buttons.length) return false;

    buttons.forEach(button => {
      button.addEventListener('click', () => debounced());
    });
    this.onOpen(true);
  }

  onToggle = toggle => {
    if (toggle) {
      this.sidebarTarget.classList.add('open');
      document.getElementById('sidebar-overlay').classList.add('open');
      document.body.classList.add('sidebar-open');
    } else {
      document.querySelector('.sidebar').classList.remove('open');
      document.getElementById('sidebar-overlay').classList.remove('open');
      document.body.classList.remove('sidebar-open');

      let messagesWrapper = this.sidebarTarget.querySelector('.sidebar-messages');
      if (messagesWrapper) {
        messagesWrapper.innerHTML = null;
      }

      let sidebarContentWrapper = this.sidebarTarget.querySelector('.sidebar-content');
      if (sidebarContentWrapper) {
        if (sidebarContentWrapper.dataset.clearOnClose === 'false') return;

        sidebarContentWrapper.innerHTML = null;
      }
    }
  };

  bindClickOutsideElement = () => {
    if (Helpers.isEmpty(this.sidebarTarget.dataset.disabledOutsideClick)) return;

    Helpers.clickOutsideElement(['[data-sidebar-open]', '.sidebar'], () => {
      if (document.querySelector('.sidebar-overlay.open')) {
        this.onToggle(false);
      }
    });
  };

  bindToOpen() {
    document.addEventListener('sidebar:open', () => this.onToggle(true));
  }

  bindToClose() {
    document.addEventListener('sidebar:close', () => this.onToggle(false));
  }

  bindToScrollTop() {
    document.addEventListener('sidebar:scroll-top', () => this.onScrollTop());
  }

  onScrollTop = () => {
    const sidebarContent = this.sidebarTarget.querySelector('.sidebar-content');
    if (!sidebarContent) {
      return;
    }

    sidebarContent.scrollTop = 0;
  };

  onResize = () => {
    const closeOnLargerSize = () => {
      if (window.innerWidth >= 768) {
        this.onToggle(false);
      }
    };

    window.addEventListener('resize', closeOnLargerSize);
  };

  setPosition = () => {
    const position = this.sidebarTarget.dataset.sidebarPosition;

    if (!position) {
      this.sidebarTarget.dataset.sidebarPosition = 'right';
    }
  };

  createSidebarObserver = () => {
    const observer = new MutationObserver(() => this.onClose());
    const options = { attributes: true, childList: true, subtree: true };

    observer.observe(this.sidebarTarget, options);
  };

  bindToOpenUrl() {
    this.sidebarTarget.addEventListener('sidebar:open-url', e => this.openUrl(e.detail.url));
  }

  openUrl = url => {
    fetch(url, { headers: { Accept: 'application/json' } })
      .then(response => response.json())
      .then(json => {
        this.sidebarTarget.querySelector('.sidebar-header').innerHTML = json.title;
        let parser = new DOMParser();
        let doc = parser.parseFromString(json.html, 'text/html');
        let sidebarContent = doc.querySelector('.sidebar-content');
        let notifications = doc.querySelector('#messages');

        if (notifications !== null) {
          this.sidebarTarget.querySelector('.sidebar-notifications').innerHTML = notifications.innerHTML;
        }
        if (sidebarContent !== null) {
          this.sidebarTarget.querySelector('.sidebar-content').innerHTML = sidebarContent.innerHTML;
        } else {
          this.sidebarTarget.querySelector('.sidebar-content').innerHTML = json.html;
        }
        if (json.messages) {
          this.sidebarTarget.querySelector('.sidebar-messages').innerHTML = json.messages;
        }
        // TODO: maybe move this code to one place
        // there is in sidebar_navigation_controller.js and sidebar_controller.shared.js
        let sidebarHeaderContent = doc.querySelector('.sidebar-header-content');
        sidebarHeaderContent = sidebarHeaderContent || '';
        this.sidebarTarget.querySelector('.sidebar-header-additional').innerHTML = sidebarHeaderContent.innerHTML || '';

        this.onToggle(true);
      });
  };
}
