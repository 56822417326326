import { Controller } from '@hotwired/stimulus';

export class FakeFileInputControllerShared extends Controller {
  static targets = ['attachedFileWrapper', 'attachedFileList', 'removeAll'];

  onRemoveAll() {
    const wrapper = this.removeAllTarget.closest('.fake-file-wrapper');
    const inputFile = wrapper.querySelector('.fake-file-input');
    const attachments = wrapper.querySelectorAll('.fake-attached-file');

    inputFile.value = '';

    if (!attachments.length) return;

    attachments.forEach(item => item.remove());
    this.attachedFileWrapperTarget.style.display = 'none';
  }

  onChange(event) {
    const inputFile = event.currentTarget;
    const files = inputFile.files;

    const getTemplate = file => `
      <li class="fake-attached-file">
        <a href="#" class="fake-file-name" title="Remove Attachment">${file.name}</a>
      </li>
    `;

    const template = Array.from(files)
      .map((_, index) => getTemplate(files.item(index)).trim())
      .join('');

    if (!template) return;

    this.attachedFileListTarget.insertAdjacentHTML('afterbegin', template);
    this.attachedFileWrapperTarget.style.display = 'block';
    event.currentTarget.parentElement.classList.add('fake-input-file-attached');
  }
}
