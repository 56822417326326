import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.videoUrl = this.data.get('videoUrl');
    this.apiKey = this.data.get('apiKey');
    this.sessionId = this.data.get('sessionId');
    this.token = this.data.get('token');
    this.label = this.data.get('label');
    this.rootPath = this.data.get('rootPath');

    this.openVideoConsultationRoom();
  }

  openVideoConsultationRoom() {
    TurbolinksAdapter.openVideoConsultationRoom({
      url: this.videoUrl,
      videoConsultationId: '',
      apiKey: this.apiKey,
      videoSessionId: this.sessionId,
      token: this.token,
      clinicianName: '',
      onConnectAttemptUrl: '',
      publishingStartUrl: '',
      onDisconnectAttemptUrl: '',
      publishingStopUrl: '',
      waitingLabel: this.label
    });
  }

  reset() {
    TurbolinksAdapter.visit(this.rootPath, { action: 'resetRoot' });
  }
}
