import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.onIframePostMessage();
  }

  onIframePostMessage = () => {
    const message = {
      sender: 'hh-booking-widget',
      data: {
        height: document.getElementById('main-content').clientHeight
      }
    };

    window.parent.postMessage(message, '*');
  };
}
