import { Controller } from '@hotwired/stimulus';
import { Helpers } from '../utils/helpers';
import { VideoLayout } from '../utils/video_layout';

export default class extends Controller {
  static targets = ['userContainer', 'userHeader', 'userControls'];

  connect() {
    this.videoLayout = new VideoLayout();

    this.userHeaderOpen = true;
    this.bindUserBarListener();
  }

  bindUserBarListener() {
    const toggle = () => {
      this.videoLayout.doPublishingLayout();

      if (this.userHeaderOpen) {
        this.userHeaderOpen = false;
        this.userContainerTarget.classList.add('video-user-closed');
      } else {
        this.userHeaderOpen = true;
        this.userContainerTarget.classList.remove('video-user-closed');
      }
    };

    const debounced = Helpers.debounce(toggle, 200);
    this.userControlsTarget.addEventListener('click', () => debounced());
  }
}
