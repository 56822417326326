import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form', 'button', 'chargeButton', 'modalControl'];

  charge() {
    import('@stripe/stripe-js').then(stripe => {
      this.disableChargeButton();
      this.displayPaymentForm(stripe);
      this.enableChargeButton();
    });
  }

  async createStripeElements(stripe, self) {
    const stripePublishableKey = self.formTarget.getAttribute('data-publishable-key');
    const stripe_connected = self.formTarget.getAttribute('data-stripe-connected') === 'true';
    if (stripe_connected) {
      const stripe_account = self.formTarget.getAttribute('data-stripe-account');
      self.stripe = await stripe.loadStripe(stripePublishableKey, { stripeAccount: stripe_account });
    } else {
      self.stripe = await stripe.loadStripe(stripePublishableKey);
    }

    const elements = self.stripe.elements();

    const defaultStyle = {
      base: {
        color: '#555',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#a94442',
        iconColor: '#a94442'
      }
    };

    self.card = elements.create('card', { style: defaultStyle, hidePostalCode: true });
    self.card.mount('#card-element');
    self.card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      displayError.querySelector('span').textContent = event.error != null ? event.error.message : '';
    });

    self.buttonTarget.disabled = false;
    $('.modal-backdrop').css('z-index', 900);
    $('#main-nav').css('z-index', 890);
  }

  startPayment = event => {
    event.preventDefault();
    this.clearPreviousErrors();
    this.disableButton();
    this.toggleModalControls(false);

    $.ajax({
      type: 'POST',
      url: this.formTarget.getAttribute('data-url'),
      data: $('form').serialize(),
      success: this.confirmPayment
    });
  };

  confirmPayment = payment_intent => {
    if (payment_intent.client_secret) {
      this.handleCardPayment(payment_intent);
    }
  };

  handleCardPayment = payment_intent => {
    const errorElement = $('#card-errors > span');
    const formTarget = this.formTarget;
    let enableButton = this.enableButton;

    this.stripe.handleCardPayment(payment_intent.client_secret, this.card).then(function (result) {
      if (result.error) {
        errorElement.text(result.error.message);
        enableButton();
      } else {
        $('#paymentIntentId').val(payment_intent.id);
        Rails.fire(formTarget, 'submit');
      }
    });
  };

  clearPreviousErrors() {
    $('#in-video-payment.modal #form-errors').html('');
  }

  disableButton() {
    this.buttonTarget.disable = true;
    $(this.buttonTarget).addClass('disabled');
    $(this.buttonTarget).find('.fa-spinner').removeClass('hidden');
    $(this.buttonTarget).find('.button-label').addClass('hidden');
  }

  enableButton() {
    let btn = $('.btn');
    btn.removeClass('disabled');
    btn.find('.fa-spinner').addClass('hidden');
    btn.find('.button-label').removeClass('hidden');
  }

  toggleModalControls(boolean) {
    this.modalControlTargets.forEach(control => (control.disabled = !boolean));
  }

  disableChargeButton() {
    $(this.chargeButtonTarget).prop('disabled', true);
    $(this.chargeButtonTarget).addClass('disabled');
    $(this.chargeButtonTarget).find('.fa-spinner').removeClass('hidden');
  }

  enableChargeButton() {
    $(this.chargeButtonTarget).prop('disabled', false);
    $(this.chargeButtonTarget).removeClass('disabled');
    $(this.chargeButtonTarget).find('.fa-spinner').addClass('hidden');
  }

  displayPaymentForm(stripe) {
    let createStripeElements = this.createStripeElements;
    let self = this;

    $.ajax({
      type: 'get',
      url: this.chargeButtonTarget.getAttribute('data-url'),
      dataType: 'script',
      success: function () {
        createStripeElements(stripe, self);
      }
    });
  }
}
