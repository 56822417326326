import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.rootPath = this.data.get('rootPath');
  }

  reset() {
    TurbolinksAdapter.visit(this.rootPath, { action: 'resetRoot' });
  }
}
