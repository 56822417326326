import * as Ladda from 'ladda';

export default class PluginsControllerShared {
  init() {
    this.onListen();
  }

  run() {
    this.bindLadda();
  }

  bindLadda() {
    Ladda.bind('.ladda-button');
  }

  onListen() {
    document.addEventListener('turbolinks:load', () => this.run());
    document.addEventListener('sidebar:open', () => this.run());
    document.addEventListener('content:replaced', () => this.run());
  }
}
