import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container', 'form'];

  options = {
    timeout: 10000
  };

  connect() {
    if (!this.hasContainerTarget) return;
    this.onLoad();
    this.onShowOverlay();
    this.onHideOverlay();
  }

  onLoad() {
    if (!this.isContainerHidden()) {
      this.containerTarget.classList.add('hidden');
    }
  }

  pageShow() {
    this.onLoad();
  }

  onSubmit() {
    const hasContainer = this.hasContainerTarget && this.isContainerHidden();
    const hasFormValidation = this.hasFormTarget && !this.formTarget.checkValidity();

    if (!hasContainer || hasFormValidation) return;

    this.showOverlay();
  }

  onShowOverlay() {
    document.addEventListener('disable-submit-overlay:show-overlay', () => this.showOverlay());
  }

  onHideOverlay() {
    document.addEventListener('disable-submit-overlay:hide-overlay', () => this.hideOverlay());
  }

  showOverlay() {
    if (this.isContainerHidden()) {
      this.containerTarget.classList.remove('hidden');
      this.hideOverlayAfterDelay();
    }
  }

  hideOverlay() {
    if (!this.isContainerHidden()) {
      this.containerTarget.classList.add('hidden');
    }
  }

  hideOverlayAfterDelay(delay = this.options.timeout) {
    setTimeout(this.hideOverlay.bind(this), delay);
  }

  isContainerHidden = () => this.containerTarget.classList.contains('hidden');
}
