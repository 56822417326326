import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['timerHeader', 'timerValue'];

  connect() {
    this.startTimer();
  }

  startTimer() {
    const tenMinutes = 60 * 10;
    const fiveMinutesLeft = 60 * 5;
    let timer = tenMinutes,
      minutes,
      seconds;
    let url, locale, prefix;
    const sessionTimeoutIntervalId = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      this.timerValueTarget.innerHTML = minutes + ':' + seconds;

      --timer;

      if (timer <= fiveMinutesLeft && this.timerHeaderTarget.classList.contains('hidden')) {
        this.timerHeaderTarget.classList.remove('hidden');
      }

      if (timer < 0) {
        timer = 0;
        clearInterval(sessionTimeoutIntervalId);
        url = this.timerHeaderTarget.dataset.startPath;
        locale = this.timerHeaderTarget.dataset.locale;
        prefix = url.split('?')[1] ? '&' : '?';
        window.location.href = url + prefix + 'sessionTimeout=true&locale=' + locale;
      }
    }, 1000);
  }
}
